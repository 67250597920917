import React from "react";
import "../style/comingSoon.css";
import Logo from "../assets/logo.png";

export class ComingSoon extends React.Component {
  constructor(props) {
    super(props);

    // Set the opening date for your shop
    this.openingDate = new Date("2024-03-12T00:00:00");

    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  componentDidMount() {
    // Update the countdown every second
    this.interval = setInterval(this.updateCountdown, 1000);
  }

  componentWillUnmount() {
    // Clear the interval to avoid memory leaks
    clearInterval(this.interval);
  }

  updateCountdown = () => {
    const now = new Date().getTime();
    const timeDifference = this.openingDate - now;

    if (timeDifference > 0) {
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      this.setState({ days, hours, minutes, seconds });
    } else {
      // If the opening date has passed, stop the countdown
      clearInterval(this.interval);
    }
  };

  render() {
    const { days, hours, minutes, seconds } = this.state;

    return (
      <div style={{ textAlign: "center", marginTop: "40px" }}>
        <img className="logo" src={Logo} alt="breedboutique" />
        <h1>Coming Soon</h1>

        {days > 0 || hours > 0 || minutes > 0 || seconds > 0 ? (
          <div>
            <h3>
              Opening in:{" "}
              <h3 className="date">
                {days}d {hours}h {minutes}m {seconds}s
              </h3>
            </h3>
          </div>
        ) : (
          <h2>We are now open! Explore our products.</h2>
        )}
      </div>
    );
  }
}
